.page__docs {
  display: flex;
  column-gap: 20px;
  justify-content: center;

  padding: 0 30px;
}

.docs_link {
  --ispui-link__color_regular: var(--isp-text-main-color);
  --ispui-link__color_hover: var(--isp-text-main-color);
  --ispui-link__color_active: var(--isp-text-main-color);

  text-align: center;
}
