.social__links {
  display: inline-flex;
  justify-content: center;
  column-gap: 24px;

  width: 100%;
}

.social__icon {
  width: 36px;
  height: 36px;
  display: block;
}

.social__link {
  display: block;
  text-decoration: none;
}
