.layout {
  display: flex;
  width: 100%;
}

.background {
  background-repeat: repeat;
  background-size: auto 100%;
  background-position-x: right;
  background-position-y: top;

  flex-grow: 1;
  flex-shrink: 1;
}

.top-padding {
  height: 100%;
  min-height: 30px;
  max-height: 200px;

  flex-shrink: 0.95;
}

.logo-indent {
  height: 100%;
  min-height: 30px;
  max-height: 130px;

  flex-shrink: 1;
}

.form {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  max-width: 700px;
  min-width: 700px;

  background-color: var(--isp-form-background);
  padding: 0 0 30px 0;
}
