.page__register {
  display: inline-flex;
  justify-content: center;
  width: 100%;

  margin-top: 40px;

  font-size: var(--isp-font-size_regular);
  color: var(--isp-text-secondary-color);
}
