.page__content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  padding: 0 160px 0 140px;

  flex-shrink: 0;
  flex-grow: 1;
}
