.page__form {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.form-field {
  // relative for right validation positioning
  position: relative;
}

.form-field:not(:first-child) {
  margin-top: 25px;
}

.form-field_buttons:not(:first-child) {
  margin-top: 30px;
}

.form-field_recaptcha {
  margin-top: 30px;

  // @HACK this is min height for recaptcha. Because of it's async loading and rendering error tooltip renders in wrong place
  min-height: 78px;
}

@import "__validation/page__validation";
@import "__inline-group/form__inline-group";
@import "__input/form__input";
@import "__link/form__link";
