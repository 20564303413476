.page__logo {
  display: flex;

  flex-shrink: 0;

  padding: 0 160px 0 140px;

  max-width: 100%;
  max-height: 200px;
}

.logo-image {
  max-width: 100%;
  max-height: 100%;
}
