:root {
  --isp-text-main-color: #1f364a;
  --isp-text-secondary-color: #727272;
  --isp-text-secondary-color-2: #d9d9d9;
  --isp-text-link-color: #1a76e2;
  --isp-text-link-color_hover: #0891e2;
  --isp-text-link-color_active: #025b90;
  --isp-text-link-contrast-color: #ffffff;
  --isp-select-item-hover-color: #e9f2ff;
  --isp-form-background: #ffffff;
  --isp-accent-color: #e54993;
  --isp-font-size_regular: 14px;
  --isp-border-radius: 3px;
  --isp-field-height: 35px;
  --isp-field-width: 100%;
  --isp-field-padding-left: 10px;

  /** link theming */
  --ispui-link__color_regular: var(--isp-text-link-color);
  --ispui-link__color_hover: var(--isp-text-link-color_hover);
  --ispui-link__color_active: var(--isp-text-link-color_active);
  --ispui-link__color-additional: var(--isp-text-secondary-color);
  --ispui-font-size-regular: var(--isp-font-size_regular);

  /** input theming */
  --ispui-input__height: var(--isp-field-height);
  --ispui-input__width: var(--isp-field-width);
  --ispui-input__border-radius: var(--isp-border-radius);
  --ispui-input__border-color: var(--isp-text-secondary-color-2);
  --ispui-input__border-color_active: var(--isp-text-link-color);
  --ispui-input__active-color: var(--isp-text-link-color);
  --ispui-input__accent-color: var(--isp-accent-color);
  --ispui-input__right-padding: 10px;
  --ispui-input__left-padding: var(--isp-field-padding-left);
  --ispui-input__label-left-offset: 10px;
  --ispui-input__label-fieldset-left-offset: -5px;

  /** ispui-password theming */
  ispui-password {
    display: contents;
  }

  /** select theming */
  --ispui-select-button-legend__border-color-focus: var(--isp-text-link-color);
  --ispui-select-button-legend__legend-color-focus: var(--isp-text-link-color);
  --ispui-select-button-legend__br: var(--isp-border-radius);
  --ispui-select-list__item-height: 35px;
  --ispui-select-list__max-height: 350px;
  --ispui-select-list__item-br: 3px;
  --ispui-select-list__item-checkbox-pl: 15px;
  --ispui-select-list__item-highlight-color: var(--isp-select-item-hover-color);

  // this variables are not exist in ispui-select! Added manualy through builderParams! add them in library!
  --ispui-select-search__padding: 10px;
  --ispui-select-search__margin: 0;
  --ispui-select-list__margin: 0 0 0 10px;
  --ispui-select-list__padding: 0 10px 0 0;
  --ispui-select-list__min-width: calc(400px - 20px);
  --ispui-select-list__max-width: calc(400px - 20px);

  // form select dropdown class
  .ispui-login-form-select-dropdown {
    min-width: 400px;
    max-width: 400px;
    padding: 10px 0;
  }

  ispui-select-button-legend {
    // @TODO i.ablov add customization of select through css
    --ispui-select-button-legend__height: var(--isp-field-height);
    // @HACK important cause need to override initial width
    --ispui-select-button-legend__width: var(--isp-field-width) !important;
    --ispui-select-button-legend__pr: 20px;
    --ispui-select-button-legend__pl: var(--isp-field-padding-left);
    --ispui-select-button-legend__arrow-bottom: none;
  }

  .ispui-select-button-legend__legend {
    // @TODO i.ablov add customization to fieldset lable offset
    margin-left: 5px;
  }

  /** button theming */
  --ispui-button__primary_background_regular: var(--isp-text-link-color);
  --ispui-button__primary_background_hover: var(--isp-text-link-color_hover);
  --ispui-button__primary_background_active: var(--isp-text-link-color_active);
  --ispui-button__line-height: 35px;
  --ispui-button__font: var(--isp-font-size_regular) Ubuntu, sans-serif;
  --ispui-button__padding-right: 15px;
  --ispui-button__padding-left: 15px;

  /** tooltip theming */
  --ispui-tooltip-background: var(--isp-form-background);
  --ispui-tooltip-font-main-color: var(--isp-text-main-color);
  --ispui-tooltip-font-size: var(--isp-font-size_regular);
}

body {
  display: flex;

  min-height: 100vh;
  margin: 0;
  padding: 0;

  color: var(--isp-text-main-color);

  font-family: "Ubuntu", sans-serif;
}

@import "../../node_modules/@ispui/ubuntu-fonts/fonts.css";
@import "./layout/layout.scss";
@import "./page/page.scss";
